<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>报表查询</el-breadcrumb-item>
        <el-breadcrumb-item>生成日记账</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row style="margin-bottom: 10px">
          <el-col :span="3" class="input-search">
            <el-date-picker
                v-model="search.recordMonth"
                type="month"
                placeholder="开始月份"
                style="width: 100%"
                value-format="YYYY-MM"
                format="YYYY年MM月"
                @change="changeRecord()"
            />
          </el-col>
          <el-col :span="3" class="input-search">
            <el-date-picker
                v-model="search.endMonth"
                type="month"
                placeholder="结束年月"
                style="width: 100%"
                value-format="YYYY-MM"
                format="YYYY-MM"
                @change="changeRecord()"
            />
          </el-col>
          <el-col :span="4" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="search.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadDayRecordData($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-cascader
                placeholder="请选择科目名称"
                style="width: 100%;"
                v-model="search.subjectSort"
                :props="props"
                clearable
                :options="subjectSortList"
                @change="changeDetail">
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-select v-model="search.subjectDetail" placeholder="请选择科目明细" clearable @change="loadDayRecordData()">
              <el-option
                  v-for="item in subjectDetailList"
                  :key="item.SUBJECT_DETAIL"
                  :label="item.SUBJECT_DETAIL"
                  :value="item.SUBJECT_DETAIL">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-button type="primary" @click="toPrint()"  style="margin-left: 10px;">
              <i class="iconfont icon-print" style="font-size: 15px"></i><span style="margin-right: 5px">打印</span>
            </el-button>
            <el-button type="warning" @click="doExport()" :loading="exportTip !== ''" style="margin-left: 10px;">
              <i class="iconfont icon-daochu" style="font-size: 15px"></i><span style="margin-right: 5px">导出</span>
            </el-button>
            <a ref="expLink" target="_blank" style="display: none;"></a>
            <span style="font-size: 12px; margin-left: 20px">{{ exportTip }}</span>
          </el-col>
          <el-col :span="1" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>

        <el-table
            :height="tableHeight"
            :data="dayRecordDataList"
            border
            :cell-style="cellStyleMethod">
          <el-table-column
              prop="RECORD_TIME"
              label="日期"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="ABSTRACT"
              label="摘要"
              align="center"
              show-overflow-tooltip
              min-width="120px"
          >
          </el-table-column>
          <el-table-column
              prop="VOUCHER_NUM"
              label="凭证号"
              align="center"
              show-overflow-tooltip
              width="70px"
          >
          </el-table-column>
          <el-table-column
              prop="SUBJECT_NAME"
              label="科目名称"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="SUBJECT_DETAIL"
              label="科目明细"
              show-overflow-tooltip
              align="center"
              min-width="60px"
          >
          </el-table-column>
          <el-table-column
              label="本月收入(元)"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
            <template #default="scope">
                  <span v-if="scope.row.REVENUE_MONEY !== null && scope.row.REVENUE_MONEY !== ''" class="disable">
                    {{ formatNumber(scope.row.REVENUE_MONEY) }}
                  </span>
            </template>
          </el-table-column>
          <el-table-column
              label="本月支出(元)"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
            <template #default="scope">
              <span v-if="scope.row.EXPENDITURE_MONEY !== null && scope.row.EXPENDITURE_MONEY !== ''" class="disable">
                {{ formatNumber(scope.row.EXPENDITURE_MONEY) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
              prop="qmjy"
              label="期末结余"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
            <template #default="scope">
                {{ calculateEnd(scope.$index) }}
            </template>
          </el-table-column>
          <el-table-column
              prop="DOCUMENT"
              label="单据"
              show-overflow-tooltip
              align="center"
              width="70px"
          >
          </el-table-column>
        </el-table>
        <el-drawer :size="1000"  v-model="drawerVisi" :with-header="false">
          <div style="width:100%; height: calc(100% - 10px); ">
            <iframe :src="pdffile" id="fra" style="width: 100%;height: 100%;"></iframe>
          </div>
        </el-drawer>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import apiURL from "@/components/apiUrl/api";

export default {
  name: "DayRecordReport",
  data(){
    return{
      tableHeight: 0,
      search:{
        recordMonth:"",
        endMonth:"",
        subjectSort:"",
        subjectName:"",
        subjectDetail:"",
        organOrDep:"",
        s_organid:"",
        s_depid:"",
      },
      OrganTreeData:[],
      organprops:{
        label:'text',value:'value',children:'children',
        checkStrictly: true,
        emitPath:false,
      },
      dayRecordDataList:[],
      subjectSortList:[],
      subjectDetailList:[],
      allSubjectDetail:[],
      props:{
        label:'label',value:'id',children:'children',
        emitPath:false,
      },
      exportTip: "",
      q_sort:"",
      q_detail:"",
      pdffile:"",
      drawerVisi:false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.search.recordMonth = this.current("month")
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.q_sort = this.$route.query.sort;
      this.q_detail = this.$route.query.detail;
      this.$router.replace({ path: this.$route.path });
      this.getSubjectList()
      this.getOrganTree()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 90
    },
    formatNumber(value) {
      // 使用 Intl.NumberFormat 来将数值格式化为千分位显示
      return new Intl.NumberFormat().format(value);
    },
    calculateEnd(index) {
      let rev = 0
      let exp = 0
      let qmjy = 0
      if(index !== -1 && this.dayRecordDataList[index].RECORD_TIME !== '合计') {
        if (this.dayRecordDataList[index].VOUCHER_NUM === null || this.dayRecordDataList[index].VOUCHER_NUM === "") {
          this.dayRecordDataList[index].qmjy = Number(this.dayRecordDataList[index].INITIAL_DATA_CARRYOVER)
          if(index === 0){
            this.dayRecordDataList[index].total_qm = Number(this.dayRecordDataList[index].INITIAL_DATA_CARRYOVER)
          }else{
            this.dayRecordDataList[index].total_qm = Number(this.dayRecordDataList[index - 1].total_qm) + Number(this.dayRecordDataList[index].INITIAL_DATA_CARRYOVER)
          }
          return this.formatNumber(this.dayRecordDataList[index].INITIAL_DATA_CARRYOVER)
        }else if(index>0 && this.dayRecordDataList[index].VOUCHER_NUM !== null && this.dayRecordDataList[index - 1].VOUCHER_NUM === null){
          if(this.dayRecordDataList[index].REVENUE_MONEY)
            rev = Number(this.dayRecordDataList[index].REVENUE_MONEY);
          if(this.dayRecordDataList[index].EXPENDITURE_MONEY)
            exp = Number(this.dayRecordDataList[index].EXPENDITURE_MONEY);
          qmjy = Number(this.dayRecordDataList[index - 1].total_qm) + rev - exp
          this.dayRecordDataList[index].qmjy = qmjy
          return this.formatNumber(qmjy.toFixed(2).replace(/\.00$/,''));
        }else {
          if(this.dayRecordDataList[index].REVENUE_MONEY)
            rev = Number(this.dayRecordDataList[index].REVENUE_MONEY);
          if(this.dayRecordDataList[index].EXPENDITURE_MONEY)
            exp = Number(this.dayRecordDataList[index].EXPENDITURE_MONEY);
          const qm = this.dayRecordDataList[index - 1]?.qmjy;
          if(typeof qm !== "undefined"){
            qmjy = Number(qm) + rev - exp
            this.dayRecordDataList[index].qmjy = qmjy
            return this.formatNumber(qmjy.toFixed(2).replace(/\.00$/,''));
          }else{
            qmjy = rev - exp
            this.dayRecordDataList[index].qmjy = qmjy
            return this.formatNumber(qmjy.toFixed(2).replace(/\.00$/,''));
          }
        }
      }
    },

    current(d) {
      var nowDate = new Date();
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate()
      };
      if (d === "month")
        return date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month);
      else if (d === "day")
        return date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month) + "-" + (date.day >= 10 ? date.day : "0" + date.day);
    },
    toRefresh() {
      this.loadDayRecordData("refresh")
    },
    getOrganTree(){
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },
    getSubjectList() {
      this.$axios.post("/api/report/getsubjectlist", this.search)
          .then((response) => {
            if (response.data.success) {
              this.subjectSortList = response.data.result.sort
              this.allSubjectDetail = response.data.result.detail
              if(this.subjectSortList.length > 0) {
                if(this.q_sort != null) {
                  this.search.subjectSort = this.q_sort
                }else{
                  this.search.subjectSort = this.subjectSortList[0].children[0].children[0].id
                  this.search.subjectName = this.subjectSortList[0].children[0].children[0].label
                }
                this.search.subjectDetail = ""
                this.changeDetail()
              }else {
                this.search.subjectSort = ""
                this.loadDayRecordData()
              }
            } else {
              this.$alert(response.data.result, "获取科目名称列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取科目名称列表出错")
          })
    },
    changeRecord() {
      this.getSubjectList()
    },
    changeDetail(){
      this.subjectSortList.forEach(item => {
        if (item.children != null) {
          item.children.forEach(ch => {
            ch.children.forEach(c => {
              if (c.id === this.search.subjectSort) {
                this.search.subjectName =c.label
              }
            })
          })
        }
      })
      let f = this.allSubjectDetail.filter((item) => {
        return Number(item.SUBJECT_SORT) === Number(this.search.subjectSort)
      })
      if(this.q_detail != null) {
        this.search.subjectDetail = this.q_detail
      }else{
        this.search.subjectDetail = ""
      }
      this.subjectDetailList = f
      this.loadDayRecordData()
    },

    loadDayRecordData(d) {
      if (this.search.organOrDep !== "" && this.search.organOrDep !== null) {
        const parts = this.search.organOrDep.toString().split('-');
        if (parts[0] === "company") {
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        } else {
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      } else {
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.$axios.post("/api/report/loaddayrecorddata", this.search)
          .then((response) => {
            if (response.data.success) {
              this.dayRecordDataList = response.data.result
              this.formatData(this.dayRecordDataList)
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取日记账数据列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取日记账数据列表出错")
          })

    },
    doExport () {
      this.exportTip = "日记账导出Excel中..."
      this.$axios.post("/api/report/exportdayrecorddata", {data: this.dayRecordDataList, search:this.search}, {
        responseType: "blob"
      })
          .then((response) => {
            setTimeout(this.exportTip = "", 3000)
            const err = response.headers["error-content"]
            if (typeof err === "undefined") {
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
              let fname = response.headers["content-disposition"].split("filename=")[1]
              fname = decodeURIComponent(fname)
              const a = this.$refs.expLink
              a.href = blobUrl
              a.setAttribute("download", fname)
              a.click()
            }
            else
              this.$alert(decodeURIComponent(err), "日记账导出出错")
          })
          .catch((error) => {
            this.exportTip = ""
            this.$alert(error, "日记账导出出错")
          })
    },
    cellStyleMethod({ row }) {
      if (row.RECORD_TIME === '合计') {
        return { 'text-align': 'right', 'font-weight': '600', 'background':'#f0f9eb' };
      }

    },

    formatData(info) {
      let data = JSON.parse(JSON.stringify(info));
      let total_rev = 0;
      let total_exp = 0;
      for (let key in data) {
        if(data[key].REVENUE_MONEY == null)
          data[key].REVENUE_MONEY = 0
        if(data[key].EXPENDITURE_MONEY == null)
          data[key].EXPENDITURE_MONEY = 0
        total_rev += parseFloat(data[key].REVENUE_MONEY);
        total_exp += parseFloat(data[key].EXPENDITURE_MONEY);
      }
      info.push({
        RECORD_TIME: '合计',
        REVENUE_MONEY: total_rev.toFixed(2).replace(/\.00$/,''),
        EXPENDITURE_MONEY: total_exp.toFixed(2).replace(/\.00$/,''),
      });
    },

    toPrint (){
      this.drawerVisi = true
      this.pdffile = ""
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.pdffile = apiURL+"/api/report/printdayrecord?recordMonth="+this.search.recordMonth +"&endMonth="+this.search.endMonth +"&s_organid="+this.search.s_organid +
          "&s_depid="+this.search.s_depid +"&subjectName="+ this.search.subjectName+
          "&subjectSort="+ this.search.subjectSort+"&subjectDetail="+ this.search.subjectDetail
    },
  }
}
</script>

<style scoped>

</style>
